

.custom-swiper .swiper-pagination {
    position: relative;
    margin-top: 20px;
  }
  
  .custom-swiper .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #ccc;
    opacity: 1;
    
  }
  
  .custom-swiper .swiper-pagination-bullet-active {
    background-color: #325DDE;
    width: 24px;
    height: 12px;
   transition: 0.5s  ;
   border-radius: 20px;
  }