@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  direction: rtl;
}

.add {
  bottom: 65px !important;
  transition: all 0.3s;
}

.shadow{
  box-shadow: 1px 1px 30px 2px #325DDE;
}





